exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-codeblock-js": () => import("./../../../src/pages/codeblock.js" /* webpackChunkName: "component---src-pages-codeblock-js" */),
  "component---src-pages-collection-js": () => import("./../../../src/pages/collection.js" /* webpackChunkName: "component---src-pages-collection-js" */),
  "component---src-pages-homepage-js": () => import("./../../../src/pages/homepage.js" /* webpackChunkName: "component---src-pages-homepage-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-navigation-js": () => import("./../../../src/pages/navigation.js" /* webpackChunkName: "component---src-pages-navigation-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */)
}

